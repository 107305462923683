
/* Project Card CSS */

.card-top {
  margin-bottom: 0.3rem;
}

.project-card {
  background-color: var(--blue1);
  max-width: 20rem;
}

.project-img {
  max-height: 12rem;
  /* max-width: 12rem; */
  background: repeat;
}

.project-title {
  text-align: center;
  font-weight: bold;
}

.project-description {
  padding: 0.1rem;
}

.project-card:hover {
  box-shadow: 10px 10px 4px var(--or4);
}

.project-card-body {
  margin: 5%;
}

.hrStyle1 {
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    var(--or1),
    rgba(255, 255, 255, 0)
  );
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 100%;
  font-weight: 100;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}


.projectCardButtonWrapper {
  justify-content: space-evenly;
}

.card {
  color: white;
  background: rgb(0, 24, 27);
  box-shadow: 5px 5px var(--or4);
  border: none;
  max-width: 33%;
  background-size: auto;
  /* border-radius: 17px; */
  -webkit-animation: fadein 2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s;
  /* Firefox < 16 */
  -ms-animation: fadein 2s;
  /* Internet Explorer */
  -o-animation: fadein 2s;
  /* Opera < 12.1 */
  animation: fadein 2s;
}

.project-code-btn {
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  background-color: var(--grey3);
  /*rgb(15, 155, 96);*/
}

.project-code-btn:hover {
  background-color: var(--grey4);
}

.project-like-btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  background-color: var(--or3);
  border: 1px solid transparent;
  font-size: 1rem;
}

.project-demo-btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  background-color: var(--or3);
  border: 1px solid transparent;
  font-size: 1rem;
}

.project-demo-btn:disabled {
  background-color: var(--or4);
}

.project-demo-btn:hover {
  background-color: var(--or4);
}

.projectBtn {
  padding: 10px;
  background-position: right top;
  background: var(--or3);
  /* border: 2px solid white; */
  display: inline-block;
  font-weight: 400;
  box-shadow: 5px 5px #000000;
  text-align: center;
  vertical-align: middle;
}

.projectBtn:hover {
  background: var(--or2);
  box-shadow: 10px 10px 4px #000000;
}

/* Badges CSS*/


.codeIcon {
  max-width: 1.3rem;
  max-height: 1.3rem;
}

.mysqlBadge {
  /* background: linear-gradient(#144e61 0%, #e57911 100%); */
  background: #e57911;
}

.vueBadge {
  background: #1e6144;
}

.nodeBadge {
  background: #022702;
  color: white;
}

.mongoBadge {
  background: #805d29;
}
.python-badge {
  background: rgb(233, 245, 75);
}

.kivyBadge {
  background: rgb(92, 92, 92);
}

.list-group-skill {
  background: #790a8f;
  margin-bottom: 1px;
}
