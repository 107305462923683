@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
      padding: 1rem;
      /* padding-left: .5rem; */
  }
}


.nav-link .active {
  background-color: var(--grey2);
}

.nav-link {
  font-size: 16px;
  background-color: var(--grey3);
  color: black;
}

.nav-link:hover {
  background-color: var(--green1);
  color: black;
}

.navbar {
  background-color: var(--blue1);
}

.nav-item,
.nav-link {
  padding: 5px ;
  margin: 5px 10px;
  border-radius: 8px;
}

.navbar-brand {
  max-width: 50px;
  max-height: 50px;
}

.dropdown-menu {
  background-color: #414141;
}

.dropdown-menu-backup {
  background-color: #9edacb;
}

.dropdown-item {
  color: white;
}

.dropdown-item:hover {
  background-color: rgba(65, 65, 65, 1);
  background-image: linear-gradient(
    rgba(121, 253, 202, 0.7),
    rgba(121, 253, 202, 0.7)
  );
}

#NavMenu {
  text-align: center;
}

.navbar-center {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.navbar-nav .disabled {
  background: black;
  color: grey;
}
