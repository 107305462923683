.name {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.ftr {
  margin-top: 1rem;
  margin-bottom: 0rem;
  background-color: var(--grey4);
  color: var(--grey1);
}

#icons i {
  color: var(--grey1);
  box-shadow: .3rem .3rem #000000;
  background-color: var(--grey3);
  background: var(--grey3);
  padding: 1rem;
  margin: 1rem;
}

#icons .fab:hover {
  color: var(--grey1);
  box-shadow: .55rem .55rem #000000;
  background-color: var(--grey3);
  background: var(--grey3);
  color: var(--grey1);
  padding: 1rem;
}