.pic-of-me {
  border-radius: 50%;
  max-width: 75%;
  box-shadow: 3rem  0px var(--or3);
}

#home {
  background: var(--grey1);
  /* font-family: Arial, Helvetica, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 1rem;
  padding-bottom: 2rem;
  overflow-y: auto;
  overflow: auto;
}

/* start social section */
.social-icon-wrapper {
  display: flex;
  align-items: center;
  list-style: none;
}

.social-icon-wrapper a {
  font-size: 1rem;
  text-decoration: none;
  background: var(--or1);
  min-width: 60px;
  min-height: 60px;
  display: flex;
  margin: 0.5rem;
  justify-content: center;
  align-items: center;
  /* transition: 1s ease-in; */
  box-shadow: 0.3rem 0.3rem #000000;
}

.social-icon-wrapper a:hover {
  box-shadow: 0.55rem 0.55rem #000000;
}
/* end social section */

.hrStyle1 {
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}

.project-btn {
  padding: 10px;
  background-position: right top;
  background: var(--or3);
  /* border: 2px solid white; */
  display: inline-block;
  font-weight: 400;
  border-radius: 0;
  box-shadow: 0.3rem 0.3rem #000000;
  text-align: center;
  vertical-align: middle;
}

.project-btn:hover {
  background: var(--or2);
  box-shadow: 0.55rem 0.55rem #000000;
}

.profile-btn {
  padding: 10px;
  background-position: right top;
  background: var(--or3);
  border-radius: 0;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  box-shadow: 0.3rem 0.3rem #000000;
  font-size: 1rem;
  line-height: 1.5;
}

.profile-btn:hover {
  background: var(--or2);
  box-shadow: 0.55rem 0.55rem #000000;
}
