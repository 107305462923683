/* Light mode */
:root {
  --yellow1: #f5f0bb;
  --greenyellow1: #c4dfaa;
  --greenyellow2: #90aa79;
  --green1: #90c8ac;
  --blue1: #73a9ad;
  --or1: #f58958;
  --or2: #e95f21;
  --or3: #aa2d0f;
  --or4: #612a13;
  --grey1: #aec3bc;
  --grey2: #80949d;
  --grey3: #506270;
  --grey4: ;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--grey2);
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --yellow1: #f5f0bb;
    --greenyellow1: #c4dfaa;
    --green1: #90c8ac;
    --blue1: #73a9ad;
    --or1: #612a13;
    --or2: #aa2d0f;
    --or3: #e95f21;
    --or4: #f58958;
    --grey1: #506270;
    --grey2: #80949d;
    --grey3: #aec3bc;
    --grey4: ;
    --text-default: white;
    color: white;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--grey2);
  }
}

html,
body {
  background-color: var(--grey1);
}
