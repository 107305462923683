* {
  box-sizing: border-box;
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-bar-container {
  width: 100px;
  height: 32px;
  background: #272727;
}

.loading-bar {
  height: 32px;
  background: #f15946;
}
