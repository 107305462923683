.model-card-top {
  height: 35rem;
  width: 40rem;
  background-color: #1e6144;
}

.model-card {
  height: 35rem;
  width: 40rem;
  background-color: #1e6144;
}
