/* Main container for all pet sections */
.pet-sections {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between each pet section */
    padding: 20px;
  }
  
  /* Styling for each pet section */
  .pet-section {
    background-color: #f0f8ff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease-in-out;
  }
  
  /* Add hover effect to each section */
  .pet-section:hover {
    background-color: #e0f7fa;
  }
  
  /* Container for the pet content */
  .pet-content {
    text-align: center;
  }
  
  /* Styling the pet image */
  .pet-image {
    width: 100%;
    height: auto;
    max-width: 300px;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  /* Styling for the pet's name and description */
  .pet-section h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .pet-section p {
    font-size: 1rem;
    color: #666;
  }
/* Main container for the image gallery */
.image-gallery {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  /* Scroll container to enable horizontal scrolling */
  .scroll-container {
    display: flex;
    overflow-x: auto;
    gap: 15px; /* Space between images */
    padding: 10px 0;
    max-width: 100%; /* Ensure container doesn’t go beyond page width */
  }
  
  /* Styling for each image item */
  .image-item {
    flex-shrink: 0; /* Prevent images from shrinking */
    width: 150px; /* Set a fixed width for images */
    height: 150px; /* Optional: Maintain height for images */
    overflow: hidden;
    border-radius: 8px;
  }
  
  /* Styling for the images */
  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures images are resized to fit */
    border-radius: 8px;
  }
  
  /* Optional: Add a smooth scroll effect */
  .scroll-container::-webkit-scrollbar {
    height: 8px;
  }
  
  .scroll-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  .scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  